<template>
  <template v-if="units">
    <template v-for="item in units.line" :key="item._key">
      <online-component-show
        v-if="units.components[item.component]"
        :comp="units.components[item.component]"
        :setting="item.setting"
        :requires="units.components"
      ></online-component-show>
    </template>
  </template>
  <div v-else class="d-flex h-center v-center color-one h-full">
    <r-progress-circle size="100" indeterminate width="4"></r-progress-circle>
  </div>
</template>

<script>
import OnlineComponentShow from "@/components/onlineComponentShow";

export default {
  name: "index",
  components: { OnlineComponentShow },
  props: {
    local: String,
    unit: String,
    page: String,
  },
  data() {
    return {
      units: null,
      lunit: this.unit || "home",
      lpage: this.page || "main",
    };
  },
  created() {
    this.getu(this.lunit);
    this.getp(this.lpage);
  },
  watch: {
    lunit: function (n) {
      this.getu(n);
    },
    lpage: function (n) {
      if (n) {
        this.getp(n);
      }
    },
    unit: function (n) {
      if (!n) {
        this.lpage = "";
        setTimeout(() => {
          this.lpage = "main";
        }, 1);
      }
      this.lunit = n || "home";
    },
    page: function (n) {
      if (n) {
        this.lpage = n;
      }
    },
  },
  methods: {
    getu(name) {
      this.units = null;
      this.$r.store.pages = null;
      this.$axios
        .get(
          `home/layout/main/${name}/${
            this.$r.breakpoint.lgAndUp ? "lg" : "sm"
          }/${this.local}`,
          { params: { v: this.$r.store.version } }
        )
        .then(
          ({ data }) => {
            if (
              data.meta &&
              data.meta[this.local] &&
              data.meta[this.local].translate
            ) {
              this.$translate.setMessages(
                data.meta[this.local].translate,
                this.local
              );
            }
            this.units = data;
          },
          (e) => {
            if (e.response.data.path) {
              this.$router.push({ path: "/" + e.response.data.path });
            }
          }
        );
    },
    getp(name) {
      this.$r.store.pages = null;
      this.$axios
        .get(
          `home/layout/main/${name}/${
            this.$r.breakpoint.lgAndUp ? "lg" : "sm"
          }/${this.local}`,
          { params: { v: this.$r.store.version } }
        )
        .then(
          ({ data }) => {
            if (data.meta) {
              if (data["meta"][this.local]) {
                data["meta"] = data["meta"][this.local];
              }
              if (data.meta && data.meta.translate) {
                this.$translate.setMessages(data.meta.translate, this.local);
              }
            }

            this.$r.store.pages = data;
          },
          (e) => {
            if (e.response.data.path) {
              this.$router.push({ path: "/" + e.response.data.path });
            }
          }
        );
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
