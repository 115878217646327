<template>
  <r-text-input
    readonly
    :model-value="modelValue"
    :key="modelValue"
  ></r-text-input>
</template>

<script>
export default {
  name: "r-id-input",
  props: {
    modelValue: String,
  },
  created() {
    if (!this.modelValue) {
      this.$emit("update:model-value", this.$helper.uniqueId(6));
    }
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
