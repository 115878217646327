import { createApp } from 'vue/dist/vue.esm-bundler';
import App from "./Index.vue";
import Axios from "./plugins/axios";
import "./registerServiceWorker";
import router from "./router/index";
import renusify from "renusify";
import PageContent from "@/components/pageContent";
import formMaker from "@/components/formMaker";
import installApp from "@/components/installApp";
import colorGet from "@/components/colorGet";
import idInput from "@/components/idInput";
import {
  rApp,
  rAddressInput,
  rAvatar,
  rBottomNavigation,
  rBottomNavigationCircle,
  rBreadcrumbs,
  rBtn,
  rBtnConfirm,
  rCalendar,
  rCamInput,
  rCard,
  rChart,
  rChartMap,
  //rChat,
  rCheckboxInput,
  rCheckInput,
  rChip,
  //rCodeEditor,
  rCol,
  rColorInput,
  rConfirm,
  rContainer,
  rContent,
  rCountDown,
  rCropper,
  rDateInput,
  rDivider,
  rFileInput,
  rFloat,
  rForm,
  rFormCreator,
  rGroupInput,
  rIcon,
  //rHtml2pdf,
  //rHtml2pdfPageBreak,
  rImg,
  rInfiniteBox,
  rInfiniteDiv,
  rInfinitePage,
  rJsonInput,
  rList,
  rMap,
  //rMapRoute,
  rMapSelect,
  rMaskInput,
  rMenu,
  rMessage,
  rMeta,
  rModal,
  rNestable,
  rNotify,
  rNumberInput,
  rPasswprdInput,
  rProgressCircle,
  rProgressLine,
  rRadioInput,
  rRangeInput,
  rRatingInput,
  rRow,
  rSearchBox,
  rSelectInput,
  rSlider,
  rSpacer,
  rSwiper,
  rSwitchInput,
  rTable,
  rTableCrud,
  rTabs,
  rTelInput,
  rTextArea,
  rTextEditor,
  rTextEditorPreview,
  rTextInput,
  rTimeAgo,
  rTimeInput,
  rTimeline,
  rTimeRangeInput,
  rToolbar,
  //rTour,
  //rTree,
  rUniqueInput,
  rUnitInput,
} from "renusify/components";
import {title, skeleton,animate} from "renusify/directive"
const app = createApp(App);
app
  .use(router)
  .use(Axios)
  .use(renusify, {
    rtl: true,
    lang: "fa",
    autoAddTranslate: false,
    package: "index",
    inputs: { tile: true },
    components:{
      rApp,
      rAddressInput,
      rAvatar,
      rBottomNavigation,
      rBottomNavigationCircle,
      rBreadcrumbs,
      rBtn,
      rBtnConfirm,
      rCalendar,
      rCamInput,
      rCard,
      rChart,
      rChartMap,
      //rChat,
      rCheckboxInput,
      rCheckInput,
      rChip,
      //rCodeEditor,
      rCol,
      rColorInput,
      rConfirm,
      rContainer,
      rContent,
      rCountDown,
      rCropper,
      rDateInput,
      rDivider,
      rFileInput,
      rFloat,
      rForm,
      rFormCreator,
      rGroupInput,
      rIcon,
      //rHtml2pdf,
      //rHtml2pdfPageBreak,
      rImg,
      rInfiniteBox,
      rInfiniteDiv,
      rInfinitePage,
      rJsonInput,
      rList,
      rMap,
      //rMapRoute,
      rMapSelect,
      rMaskInput,
      rMenu,
      rMessage,
      rMeta,
      rModal,
      rNestable,
      rNotify,
      rNumberInput,
      rPasswprdInput,
      rProgressCircle,
      rProgressLine,
      rRadioInput,
      rRangeInput,
      rRatingInput,
      rRow,
      rSearchBox,
      rSelectInput,
      rSlider,
      rSpacer,
      rSwiper,
      rSwitchInput,
      rTable,
      rTableCrud,
      rTabs,
      rTelInput,
      rTextArea,
      rTextEditor,
      rTextEditorPreview,
      rTextInput,
      rTimeAgo,
      rTimeInput,
      rTimeline,
      rTimeRangeInput,
      rToolbar,
      //rTour,
      //rTree,
      rUniqueInput,
      rUnitInput,
    },
    directives: {title, skeleton,animate},
  });
window.App = app;
app.component("PageContent", PageContent);
app.component("form-maker", formMaker);
app.component("install-app", installApp);
app.component("color-get", colorGet);
app.component("r-id-input", idInput);
window.app = app.mount("#app");
