import { createRouter, createWebHistory } from "vue-router";

import site from "../views/index/index";
const notFound = () => import("../views/index/notFound");
const routes = [
  {
    path: "/:local?/:unit?/:page?",
    component: site,
    name: "base",
    props: true,
    meta: { home: true },
  },
  { path: "/:pathMatch(.*)*", name: "not_found", component: notFound },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        setTimeout(() => {
          resolve({ left: 0, top: savedPosition["top"] });
        }, 500);
      } else {
        resolve({ left: 0, top: 0 });
      }
    });
  },
});
router.beforeEach((to, from, next) => {
  if (
    to.meta.home &&
    (!to.params.local || !(to.params.local in window.app.$r.langs))
  ) {
    next({ path: `/${window.app.$r.lang}${to.fullPath}`, query: to.query });
  } else {
    next();
  }
});
export default router;
