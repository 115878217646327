<template>
  <r-switch-input
    v-model="isdark"
    class="mb-5"
    :label="$t('dark')"
  ></r-switch-input>
  <r-form>
    <r-color-input
      v-model="one"
      :label="$t('color_one')"
      format="hex"
    ></r-color-input>
    <r-color-input
      v-model="two"
      :label="$t('color_two')"
      format="hex"
    ></r-color-input>
    <r-color-input
      v-model="three"
      :label="$t('color_three')"
      format="hex"
    ></r-color-input>
    <r-btn class="color-success-text my-5" @click="setColor" outlined
      >{{ $t("send") }}
    </r-btn>
  </r-form>
</template>

<script>
import {
  argbFromHex,
  hexFromArgb,
} from "app/extension/renus/setting/view/color/string_utils";
import { Hct } from "app/extension/renus/setting/view/color/hct";
import { TonalPalette } from "app/extension/renus/setting/view/color/tonal_palette";

export default {
  name: "colorGet",
  data() {
    return {
      isdark: false,
      show: false,
      one: "#0000ff",
      two: "#4747ad",
      three: "#b01ca9",
    };
  },
  methods: {
    light(core) {
      return {
        one: hexFromArgb(core.a1.tone(40)),
        "on-one": hexFromArgb(core.a1.tone(100)),
        "one-container": hexFromArgb(core.a1.tone(90)),
        "on-one-container": hexFromArgb(core.a1.tone(10)),
        two: hexFromArgb(core.a2.tone(40)),
        "on-two": hexFromArgb(core.a2.tone(100)),
        "two-container": hexFromArgb(core.a2.tone(90)),
        "on-two-container": hexFromArgb(core.a2.tone(10)),
        three: hexFromArgb(core.a3.tone(40)),
        "on-three": hexFromArgb(core.a3.tone(100)),
        "three-container": hexFromArgb(core.a3.tone(90)),
        "on-three-container": hexFromArgb(core.a3.tone(10)),
        error: hexFromArgb(core.error.tone(40)),
        "on-error": hexFromArgb(core.error.tone(100)),
        "error-container": hexFromArgb(core.error.tone(90)),
        "on-error-container": hexFromArgb(core.error.tone(10)),
        success: hexFromArgb(core.success.tone(40)),
        "on-success": hexFromArgb(core.success.tone(100)),
        "success-container": hexFromArgb(core.success.tone(90)),
        "on-success-container": hexFromArgb(core.success.tone(10)),
        warning: hexFromArgb(core.warning.tone(40)),
        "on-warning": hexFromArgb(core.warning.tone(100)),
        "warning-container": hexFromArgb(core.warning.tone(90)),
        "on-warning-container": hexFromArgb(core.warning.tone(10)),
        info: hexFromArgb(core.info.tone(40)),
        "on-info": hexFromArgb(core.info.tone(100)),
        "info-container": hexFromArgb(core.info.tone(90)),
        "on-info-container": hexFromArgb(core.info.tone(10)),
        sheet: hexFromArgb(core.n1.tone(99)),
        "on-sheet": hexFromArgb(core.n1.tone(10)),
        "sheet-low": hexFromArgb(core.n2.tone(90)),
        "on-sheet-low": hexFromArgb(core.n2.tone(30)),
        "sheet-dark": hexFromArgb(core.n1.tone(89)),
        "sheet-light": hexFromArgb(core.n1.tone(99)),
        "sheet-container-lowest": hexFromArgb(core.n1.tone(100)),
        "sheet-container-low": hexFromArgb(core.n1.tone(96)),
        "sheet-container": hexFromArgb(core.n1.tone(94)),
        "sheet-container-high": hexFromArgb(core.n1.tone(92)),
        "sheet-container-highest": hexFromArgb(core.n1.tone(90)),
        border: hexFromArgb(core.n2.tone(50)),
        "border-low": hexFromArgb(core.n2.tone(80)),
        overlay: hexFromArgb(core.n1.tone(98)) + "7f",
      };
    },
    dark(core) {
      return {
        one: hexFromArgb(core.a1.tone(80)),
        "on-one": hexFromArgb(core.a1.tone(20)),
        "one-container": hexFromArgb(core.a1.tone(30)),
        "on-one-ontainer": hexFromArgb(core.a1.tone(90)),
        two: hexFromArgb(core.a2.tone(80)),
        "on-two": hexFromArgb(core.a2.tone(20)),
        "two-container": hexFromArgb(core.a2.tone(30)),
        "on-two-container": hexFromArgb(core.a2.tone(90)),
        three: hexFromArgb(core.a3.tone(80)),
        "on-three": hexFromArgb(core.a3.tone(20)),
        "three-container": hexFromArgb(core.a3.tone(30)),
        "on-three-container": hexFromArgb(core.a3.tone(90)),
        error: hexFromArgb(core.error.tone(80)),
        "on-error": hexFromArgb(core.error.tone(20)),
        "error-container": hexFromArgb(core.error.tone(30)),
        "on-error-container": hexFromArgb(core.error.tone(90)),
        success: hexFromArgb(core.success.tone(80)),
        "on-success": hexFromArgb(core.success.tone(20)),
        "success-container": hexFromArgb(core.success.tone(30)),
        "on-success-container": hexFromArgb(core.success.tone(90)),
        warning: hexFromArgb(core.warning.tone(80)),
        "on-warning": hexFromArgb(core.warning.tone(20)),
        "warning-container": hexFromArgb(core.warning.tone(30)),
        "on-warning-container": hexFromArgb(core.warning.tone(90)),
        info: hexFromArgb(core.info.tone(80)),
        "on-info": hexFromArgb(core.info.tone(20)),
        "info-container": hexFromArgb(core.info.tone(30)),
        "on-info-container": hexFromArgb(core.info.tone(90)),
        sheet: hexFromArgb(core.n1.tone(6)),
        "on-sheet": hexFromArgb(core.n1.tone(90)),
        "sheet-low": hexFromArgb(core.n2.tone(30)),
        "on-sheet-low": hexFromArgb(core.n2.tone(90)),
        "sheet-dark": hexFromArgb(core.n1.tone(4)),
        "sheet-light": hexFromArgb(core.n1.tone(24)),
        "sheet-container-lowest": hexFromArgb(core.n1.tone(4)),
        "sheet-container-low": hexFromArgb(core.n1.tone(10)),
        "sheet-container": hexFromArgb(core.n1.tone(12)),
        "sheet-container-high": hexFromArgb(core.n1.tone(17)),
        "sheet-container-highest": hexFromArgb(core.n1.tone(24)),
        border: hexFromArgb(core.n2.tone(60)),
        "border-low": hexFromArgb(core.n2.tone(30)),
        overlay: hexFromArgb(core.n1.tone(6)) + "7f",
      };
    },
    get() {
      const hct1 = Hct.fromInt(argbFromHex(this.one));
      const hct2 = Hct.fromInt(argbFromHex(this.two));
      const hct3 = Hct.fromInt(argbFromHex(this.three));
      const hue = hct1.hue;
      const chroma = hct1.chroma;
      const hue2 = hct2.hue;
      const chroma2 = hct2.chroma;
      const hue3 = hct3.hue;
      const chroma3 = hct3.chroma;
      let core = {};
      core.a1 = TonalPalette.fromHueAndChroma(hue, Math.max(48, chroma));
      core.a2 = TonalPalette.fromHueAndChroma(hue2, Math.max(48, chroma2));
      core.a3 = TonalPalette.fromHueAndChroma(hue3, Math.max(48, chroma3));
      core.n1 = TonalPalette.fromHueAndChroma(hue, 4); //sheet
      core.n2 = TonalPalette.fromHueAndChroma(hue, 8); //sheet low
      core.error = TonalPalette.fromHueAndChroma(25, 84);
      core.warning = TonalPalette.fromHueAndChroma(80, 84);
      core.success = TonalPalette.fromHueAndChroma(140, 84);
      core.info = TonalPalette.fromHueAndChroma(250, 84);
      this.$storage.set("is_dark", this.isdark);
      if (this.isdark) {
        return this.dark(core);
      }
      return this.light(core);
    },
    setColor() {
      this.$emit("finish", this.get());
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
