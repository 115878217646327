<template>
  <component v-if="page" ref="c" :is="page" v-bind="setting"></component>
</template>

<script>
import { getCurrentInstance, markRaw } from "vue";

export default {
  name: "onlineComponent",
  props: {
    comp: Object,
    requires: Object,
    setting: Object,
    active: Boolean,
  },
  data() {
    return {
      currentInstance: getCurrentInstance(),
      loaded: false,
      styles: "",
    };
  },
  created() {
    this.regRequires(Object.values(this.requires));
  },
  computed: {
    page() {
      if (!this.loaded) {
        return false;
      }
      return this.currentInstance.appContext.components[
        "c-" + this.comp["title"]
      ];
    },
  },
  methods: {
    regRequires(requires) {
      if (requires.length === 0) {
        this.loaded = true;
        return true;
      } else {
        const a = Object.keys(this.currentInstance.appContext.components);
        this.styles = "";
        requires.forEach((item) => {
          if (!a.includes(`c-${item["title"]}`)) {
            const c = this.load(item["title"], item.code.html, item.code.js);
            if (!c) {
              return;
            }
            window.App.component("c-" + item["title"], c);
            if (item.code.style) {
              this.styles += item.code.style + " ";
            }
          }
        });
        if (this.styles) {
          let s = document.createElement("style");
          s.innerText = this.styles.replace(/[\r\n\t]/g, "");
          s.setAttribute("f", "component-" + this.comp["title"]);
          s.setAttribute("type", "text/css");
          window.document.head.append(s);
        }
        this.loaded = true;
        return true;
      }
    },
    load(name, html, js) {
      try {
        return markRaw({
          name: "c-" + name,
          template: html,
          ...eval("Object({" + js + "})"),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
