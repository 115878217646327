<template>
  <template v-if="$r.store.pages">
    <r-meta
      :title="$r.store.pages.meta.title || 'app'"
      :description="$r.store.pages.meta.seo_description || ''"
      :keywords="$r.store.pages.meta.seo_keywords || []"
    ></r-meta>
    <online-component-show
      v-for="item_page in $r.store.pages.line"
      :key="item_page._key"
      :comp="$r.store.pages.components[item_page.component]"
      :setting="item_page.setting"
      :requires="$r.store.pages.components"
    ></online-component-show>
  </template>
  <div
    v-else
    class="d-flex h-center v-center title color-grey-lighten-3"
    style="height: 500px"
    key="0"
  >
    <r-progress-circle
      indeterminate
      size="100"
      class="color-one-text"
    ></r-progress-circle>
  </div>
</template>

<script>
import onlineComponentShow from "@/components/onlineComponentShow";

export default {
  name: "pageContent",
  components: { onlineComponentShow },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
